import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  isShowDialog: boolean;
  constructor(public dialog: MatDialog) {}

  showDialog<T>(dialogType: ComponentType<T>, config?: MatDialogConfig, onClosed?: (value: any) => void) {
    const dialogRef = this.dialog.open(dialogType, config);
    this.isShowDialog = true;
    if (!onClosed) {
      return;
    }
    dialogRef.afterClosed().subscribe(param => {
      onClosed(param);
      this.isShowDialog = false;
    });
  }
  /**
   * close all dialog
   */
  closeAllDialog() {
    this.dialog.closeAll();
  }
}
