import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { Observable, Subject, of, from } from 'rxjs';
import { skip, take, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScheduleOperationManagerService {
  /**
   * Schedule operation information url
   */
  private scheduleOperationManagerUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/schedule-operation';

  tempoSuspension = '臨時運休';

  constructor(private http: HttpClient) {}

  /**
   * Save operation manager
   * @param payload
   * @returns
   */
  saveOperationManager(payload: any) {
    return this.http.post(`${this.scheduleOperationManagerUrl}/save-schedule-operation-info`, payload);
  }
  listScheduleOperationFromAPI: any;
  listScheduleOperation = new Subject<any>();
  headerScheduleOperations: any;

  getScheduleOperationFromAPI(index, size, isScrollDown = true): Observable<any> {
    // lazyloading data when scroll
    if (this.listScheduleOperationFromAPI && this.listScheduleOperationFromAPI.entry) {
      const schedulesDaily = this.listScheduleOperationFromAPI.entry;
      return from(schedulesDaily).pipe(
        skip(isScrollDown ? index : index - size * 4 > 0 ? index - size * 4 : 0),
        take(this.listScheduleOperationFromAPI.entry.length < index ? this.listScheduleOperationFromAPI.entry.length + size - index : size),
        toArray()
      );
    } else {
      return from([]);
    }
  }

  arrConvertData(index, size, isScrollDown = true, dataArr): any[] {
    //func to splice arr (lazyloading)
    let numDataNeedSplice = dataArr.length - size * 3;
    if (numDataNeedSplice < 0) {
      numDataNeedSplice = 0;
    }
    if (isScrollDown) {
      dataArr.splice(0, numDataNeedSplice);
    } else {
      dataArr.splice(size * 3, numDataNeedSplice);
    }
    return dataArr;
  }

  convertDataOperationApi() {
    this.listScheduleOperationFromAPI.entry.sort((a, b) =>
      parseInt(a.time.replace(/:?/g, '')) > parseInt(b.time.replace(/:?/g, '')) ? 1 : -1
    );

    this.listScheduleOperationFromAPI.entry.forEach((e, index) => {
      e.key = index + 1;
      if (!e.suspension) {
        e.suspension = false;
      }
      // if (e.item6 && (e.item6.toLowerCase() == 'temporary' || e.item6 == this.tempoSuspension)) {
      //   e.suspension = true;
      //   e.item6 = '';
      // }
    });
  }

  public getShowHiddenItem(areaName: string): Observable<any> {
    const params = new HttpParams().set('areaName', areaName);
    return this.http.get<any>(`${this.scheduleOperationManagerUrl}/get-all-items`, { params });
  }

  public saveShowHiddenItem(data: string, areaName): Observable<any> {
    const params = new HttpParams().set('areaName', areaName);
    return this.http.post<any>(`${this.scheduleOperationManagerUrl}/save-items`, data, { params });
  }

  public getAreaItemSetting(areaName: string): Observable<any> {
    const params = new HttpParams().set('areaName', areaName);
    return this.http.get<any>(`${this.scheduleOperationManagerUrl}/get-all-items-setting`, { params });
  }

  public saveAreaItemSetting(data: string, areaName): Observable<any> {
    const params = new HttpParams().set('areaName', areaName);
    return this.http.post<any>(`${this.scheduleOperationManagerUrl}/save-items-setting`, data, { params });
  }

  public getApiUpdate(): Observable<any> {
    return this.http.get(`${this.scheduleOperationManagerUrl}/get-api-update-data`, {
      responseType: 'text'
    });
  }
}
