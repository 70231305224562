import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { Observable } from 'rxjs';
import { AppDesignDTO } from './../../model/entity/ticket-editor/dto/app-design-DTO';

@Injectable({
  providedIn: 'root'
})
/**
 * Class Application Servive
 */
export class ApplicationService {
  /**
   * URL for common simple media
   */
  simpleApplicationUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/application';

  constructor(private http: HttpClient) {}
  /**
   * Be used by TICKET-EDITOR-COMPONENT
   * get all application
   *
   * @param folderId
   * @returns
   */
  findAllApplication(): Observable<Array<ApplicationDTO>> {
    return this.http.get<Array<ApplicationDTO>>(`${this.simpleApplicationUrl}/${Constant.TICKET_EDITOR_COMPONENT_URL}/get-all-application`);
  }

  /**
   * Be used by TICKET-EDITOR-COMPONENT
   * add New App Design
   *
   * @param folderId
   * @returns
   */
  createAppDesign(appDesignDTO: AppDesignDTO): Observable<any> {
    return this.http.post<Array<AppDesignDTO>>(
      `${this.simpleApplicationUrl}/${Constant.TICKET_EDITOR_COMPONENT_URL}/create-appdesign`,
      appDesignDTO
    );
  }

  /**
   * Add new application
   * @param application
   */
  createApplication(application: any): Observable<any> {
    return this.http.post<any>(`${this.simpleApplicationUrl}/${Constant.TICKET_EDITOR_COMPONENT_URL}/create-application`, application);
  }

  /**
   * delete applicatiopn
   * @param idApp
   */
  deleteApplicationById(idApp: Number): Observable<any> {
    return this.http.delete<any>(`${this.simpleApplicationUrl}/${Constant.TICKET_EDITOR_COMPONENT_URL}/delete-application/` + idApp);
  }

  /**
   * delete applicatiopn
   * @param idApp
   */
  deleteAppDesignByIds(idAppDesign: Number[]): Observable<any> {
    return this.http.post<any>(`${this.simpleApplicationUrl}/${Constant.TICKET_EDITOR_COMPONENT_URL}/delete-appdesign`, idAppDesign);
  }

  /**
   * handling After Delivery App
   * @param idApplication
   * @param idAppDesign
   * @param idAppApi
   * @returns
   */
  handlingAfterDeliveryApp(idApplication: Number, appDesignDTO: AppDesignDTO): Observable<any> {
    return this.http.post<any>(
      this.simpleApplicationUrl + '/' + Constant.TICKET_EDITOR_COMPONENT_URL + '/handling-after-delivery?idApplication=' + idApplication,
      appDesignDTO
    );
  }
}
